import Head from "next/head";
import favicon1 from "../../public/favicon/apple-icon-57x57.png";
import manifest from "../../public/favicon/manifest.json";

const Seo = (props) => {
  const {
    title,
    description,
    url,
    keywords,
    script,
    purchase,
    img,
    isPurchase,
  } = props;
  const site = "https://www.isnet.net.tr";
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="keyword" content={keywords} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={favicon1.src} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={site + url} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={site + url} />
        <link rel="icon" type="image/x-icon" href={favicon1.src} />
        <link rel="manifest" href={JSON.stringify(manifest)} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@isnet_TR" />
        <meta name="twitter:creator" content="@isnet_TR" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:src" content={img} />
        <script
          dangerouslySetInnerHTML={{
            __html: `var f=function(){var t=window;if('function'!=typeof t.InfosetChat){var n=document,e=function(){e.c(arguments)};e.q=[],e.c=function(t){e.q.push(t)},t.InfosetChat=e;var a=function(){var t=n.createElement('script');t.type='text/javascript',t.async=!0,t.src='https://chatpanel.isnet.net.tr/chat/icw.js';var e=n.getElementsByTagName('script')[0];e.parentNode.insertBefore(t,e)};t.attachEvent?t.attachEvent('onload',a):t.addEventListener('load',a,!1)}};f();
    InfosetChat('boot',{widget:{apiKey:'ZTcrnlyYpEjgdhtnXDk37QdcGFhg9Szzny32Vepx'}});`,
          }}
        />
        {script ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              ${script}
            `,
            }}
          />
        ) : null}

        {isPurchase ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              ${purchase}
            `,
            }}
          />
        ) : null}
        {/* End Google Tag Manager */}
      </Head>
    </>
  );
};

export default Seo;
