import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Suggestion from "../components/suggestion/Suggestion";
import Features from "../components/features/Features";
import News from "../components/news/News";
import Seo from "../components/seo/Seo";
import BrandSlider from "../components/slider/BrandSlider";
import PartnerSlider from "../components/slider/PartnerSlider";
import Slider from "../components/slider/Slider";
import { suggestionList, featureList, sliderList } from "../constants";
import PageLayout from "../components/layout/PageLayout";
import { staticPath } from "../utils";

const Home = ({
  blog,
  news,
  newsTr,
  newsEng,
  references,
  partners,
  data,
  navbar,
  footer,
  navbarData,
  navbarFooterData,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <PageLayout
      navbarData={navbarData}
      navbar={navbar}
      footer={footer}
      navbarFooterData={navbarFooterData}
    >
      <section>
        <h1 style={{ display: "none" }}>{t("home:title")}</h1>
        <Seo
          title={t("home:title")}
          keywords={t("home:keywords")}
          description={t("home:home_description")}
          url={router.asPath}
        />
        {/* <EmblaCarousel slides={data.sliderList} /> */}
        <Slider sliders={sliderList} />

        <Features data={featureList} styleSection="section section_features" />

        {news && router.locale === "tr" ? (
          <News
            contentList={newsTr}
            pathname="bizdenhaberlericerik"
            styleSection="section section_news"
            headerText={t("home:news")}
          />
        ) : (
          <News
            contentList={newsEng}
            pathname="newscontent"
            styleSection="section section_news"
            headerText={t("home:news")}
            locale={router.locale}
          />
        )}
        {blog && router.locale === "tr" && (
          <News
            contentList={blog}
            pathname="blogicerik"
            styleSection="section section_blog"
            headerText={t("home:blog")}
          />
        )}
        {suggestionList && router.locale === "tr" && (
          <Suggestion
            styleSection="section section_suggestion"
            contentList={suggestionList}
            headerText={t("home:working_with_isnet")}
          />
        )}
        {references && (
          <BrandSlider
            contentList={references}
            headerText={t("home:references")}
          />
        )}
        {references && (
          <PartnerSlider
            contentList={partners}
            headerText={t("home:partners")}
          />
        )}
      </section>
    </PageLayout>
  );
};

export async function getStaticProps(props) {
  const [
    newsData,
    blogsData,
    referencesData,
    navbarData,
    navbarFooterData,
    footerData,
  ] = await Promise.all([
    fetch(`${process.env.ISNETURL}/news?count=9`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/blog?count=9`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/reference`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/menu`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/menu/footermenu`).then((res) => res.json()),
    fetch(`${process.env.API_URL}/footer`).then((res) => res.json()),
  ]);
  const navbarFilter =
    navbarData && navbarData?.filter((item) => item.isDeleted === false);

  let navbar, footer;
  if (navbarFilter || footerData) {
    const nav = staticPath(navbarFilter);
    navbar = nav;
    footer = footerData;
  }

  if (!blogsData || !newsData) {
    return {
      notFound: true,
    };
  }

  let references = referencesData.filter(
    (item) => item.referenceType === 1 && !item.isDeleted
  );
  let partners = referencesData.filter(
    (item) => item.referenceType === 2 && !item.isDeleted && !item.city
  );
  let newsTr = newsData.filter((item) => item.languageId === 1);
  let newsEng = newsData.filter((item) => item.languageId === 2);

  return {
    props: {
      ...(await serverSideTranslations(props.locale, ["home"])),
      blog: blogsData,
      news: newsData,
      locale: props.locale,
      references: references,
      partners: partners,
      newsTr: newsTr,
      newsEng: newsEng,

      navbar,
      footer,
      navbarData,
      navbarFooterData,
    }, // will be passed to the page component as props
    revalidate: 10,
  };
}

export default Home;
