/* import Image from "next/image"; */
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";

const Features = ({ data, styleSection }) => {
  const { locale } = useRouter();
  return (
    <div className={styleSection}>
      <Container>
        <Row gap={3}>
          {data?.map((item, index) => (
            <Fragment key={index}>
              {locale === item.language && (
                <Col lg={3} md={6} sm={6} key={item.name} className="card_col">
                  <div className="card_features">
                    <div className="card_features_inner">
                      <div className="card_features_icon card-icon">
                        <Image
                          src={item.img}
                          width={170}
                          height={170}
                          alt={item.name}
                        />
                      </div>
                      <div className="card_features_body">
                        <h3 className="card_features_title">{item.name}</h3>
                        <p className="card_features_desc">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Features;
