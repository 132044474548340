import { useState, useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import { Fragment } from "react";
import Image from "next/image";

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);
  const mediaMatch = () => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  };

  useEffect(() => {
    mediaMatch();
  }, []);

  return targetReached;
};

const PartnerSlider = ({ contentList, headerText }) => {
  const [data, setData] = useState();
  const sm = useMediaQuery(425);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    const data = contentList.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    setData(data);
  }, [contentList]);

  return (
    <div className="section_sm">
      <div className="section_header" style={{ marginBottom: 0 }}>
        <h2 className="section_title">{headerText}</h2>
      </div>
      <div className="section_sm_bg">
        <Container className="mb-5">
          <div className="slider-news-wrapper">
            <Swiper
              ref={sliderRef}
              className="slider_brand"
              slidesPerView={sm ? 1 : 5}
              spaceBetween={15}
              key="3"
              lazyPreloadPrevNext={true}
              slidesPerGroupSkip={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {data?.map((item, index) => (
                <Fragment key={index}>
                  <SwiperSlide key={index}>
                    <div className="slider_brand">
                      <a className="slider_brand_link">
                        <Image
                          src={process.env.IMAGE_URL + item.pictureUrl}
                          alt={item.title}
                          className="slider_brand_img"
                          width={100}
                          height={100}
                        />
                      </a>
                    </div>
                  </SwiperSlide>
                </Fragment>
              ))}
            </Swiper>
            <div className="slider-navigation slider-navigation-brand">
              <div onClick={handlePrev} className=" slider-navigation-prev">
                <i className="icon-angle-left" />
              </div>
              <div onClick={handleNext} className=" slider-navigation-next">
                <i className="icon-angle-right" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PartnerSlider;
