import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/router";
import { Fragment } from "react";
import AnchorLink from "../anchor/Anchor";
import Image from "next/image";

const Slider = ({ sliders }) => {
  const { locale } = useRouter();
  const revLocale = locale === "en" ? "en" : "tr";

  const [isMobile, setIsMobile] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const organizeSlidersByLanguage = (sliders) => {
    const trSliders = sliders
      .filter((slider) => slider.lang === "tr")
      .sort((a, b) => a.id.localeCompare(b.id));

    const enSliders = sliders
      .filter((slider) => slider.lang === "en")
      .sort((a, b) => a.id.localeCompare(b.id));

    return { trSliders, enSliders };
  };

  const { trSliders, enSliders } = organizeSlidersByLanguage(sliders);
  const currentSliders = locale === "en" ? enSliders : trSliders;

  useEffect(() => {
    if (swiperInstance && swiperInstance.params && currentSliders.length > 0) {
      swiperInstance.slideTo(2);
    }
  }, [swiperInstance, currentSliders]);

  return (
    <div className="slider-main slider_main">
      <Swiper
        slidesPerView={"auto"}
        navigation={{ clickable: true }}
        loop={true}
        spaceBetween={15}
        centeredSlides={true}
        className="slider_main"
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        lazyPreloadPrevNext={0}
        modules={[Navigation, Pagination, Autoplay]}
        onSwiper={setSwiperInstance} // Set swiper instance
      >
        {currentSliders?.map((value, index) => (
          <Fragment key={index}>
            <SwiperSlide key={index} id={value.id}>
              <div className="slider_bg">
                <Image
                  src={isMobile ? value.mobileBg : value.bg}
                  fill
                  sizes="(min-width: 808px) 50vw, 100vw"
                  style={{
                    objectFit: "cover",
                  }}
                  priority={index === 0}
                  loading={index === 0 ? "eager" : "lazy"}
                  alt={value.text}
                  unoptimized
                />
              </div>

              <div className="slider_inner">
                <Container className="h-100">
                  <Row className="justify-content-center h-100">
                    <Col md={12} className="h-100">
                      <div className="d-flex flex-wrap h-100 align-content-center">
                        <div className="position-relative text-center w-100">
                          <div style={{ width: "100%" }}>
                            <h4
                              className="slider_title"
                              dangerouslySetInnerHTML={{
                                __html: value.text,
                              }}
                            ></h4>
                            {value.detail && (
                              <AnchorLink
                                locale={revLocale}
                                href={value.url}
                                className="btn btn-outline-light"
                                target={value.target ? "_blank" : null}
                              >
                                {locale === "en" ? (
                                  <span>
                                    <strong> More Info </strong>{" "}
                                  </span>
                                ) : (
                                  <span>
                                    <strong> Detaylı Bilgi </strong>{" "}
                                  </span>
                                )}
                              </AnchorLink>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </SwiperSlide>
          </Fragment>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
