import { useState, useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Container } from "react-bootstrap";
import { Fragment } from "react";
import Image from "next/image";

const BrandSlider = ({ contentList, headerText }) => {
  const [data, setData] = useState();
  const sliderRef = useRef();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  useEffect(() => {
    const data = contentList.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    setData(data);
  }, [contentList]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className="section_sm mt-5">
      <div className="section_header" style={{ marginBottom: 0 }}>
        <h2 className="section_title">{headerText}</h2>
      </div>
      <div className="section_sm_bg">
        <Container className="mb-5">
          <div className="slider-news-wrapper">
            <Swiper
              ref={sliderRef}
              className="slider_brand"
              lazyPreloadPrevNext={true}
              spaceBetween={15}
              key="2"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                821: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {data?.map((item, index) => (
                <Fragment key={index}>
                  {item.referenceType === 1 && !item.isDeleted && (
                    <SwiperSlide key={index}>
                      <div className="slider_brand">
                        <a className="slider_brand_link">
                          <Image
                            src={process.env.IMAGE_URL + item.pictureUrl}
                            alt={item.title}
                            className="slider_brand_img"
                            width={100}
                            height={100}
                          />
                        </a>
                      </div>
                    </SwiperSlide>
                  )}
                </Fragment>
              ))}
            </Swiper>
            <div className="slider-navigation slider-navigation-brand">
              <div onClick={handlePrev} className="slider-navigation-prev">
                <i className="icon-angle-left" />
              </div>
              <div onClick={handleNext} className="slider-navigation-next">
                <i className="icon-angle-right" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BrandSlider;
